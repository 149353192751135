<!--
### Informational tooltip that displays on hover
 -->
<script lang="ts">
  import type { ComputePositionConfig } from "@floating-ui/dom";
  import { floating } from "@hyperfocal/actions";
  import delay from "delay";
  import infoIcon from "lucide-static/icons/circle-help.svg?raw";
  import { untrack, type Snippet } from "svelte";
  import { cubicOut } from "svelte/easing";
  import { fade } from "svelte/transition";

  type TooltipProps = {
    /** Tooltip content */
    tip: string;
    /** Placement of the tooltip */
    placement?: ComputePositionConfig["placement"];
    /** Whether the tooltip is disabled */
    disabled?: boolean;
    /** Whether the tooltip is delayed */
    delayed?: boolean;
    /** Optional content */
    children?: Snippet;
  };

  const {
    tip,
    placement = "top-start",
    disabled = false,
    delayed = false,
    children,
  }: TooltipProps = $props();

  let open = $state<boolean>();
  let trigger = $state<HTMLElement>();
  let hovering = $state<boolean>();

  $effect(() => {
    untrack(() => open);
    if (disabled || !hovering) {
      open = false;
      return;
    }

    if (delayed) {
      delay(800).then(() => (open = hovering));
    } else {
      open = hovering;
    }
  });
</script>

<style>
  .trigger:not(.custom) {
    display: inline-block;
    opacity: 0.6;
    transition: opacity 350ms ease;
    margin-left: 6px;
    transform: translateY(-10%);
    &:hover {
      opacity: 1;
    }
    & :global(svg) {
      width: var(--size-3);
      height: var(--size-3);
      stroke: currentColor;
      vertical-align: auto;
    }
  }

  .tip {
    z-index: var(--layer-5);
    width: max-content;
    max-width: var(--size-60);
    font: var(--text-xs);
    color: var(--color-text-secondary);
    background: var(--color-bg);
    border: 1px solid var(--color-border-tertiary);
    white-space: normal;
    border-radius: var(--radius-md);
    padding: var(--size-2) var(--size-3);
  }
</style>

<div data-testid="tooltip">
  <div
    class="trigger"
    role="button"
    tabindex="0"
    data-testid="tooltip-trigger"
    class:custom={children !== undefined}
    bind:this={trigger}
    onmouseenter={() => (hovering = true)}
    onmouseleave={() => (hovering = false)}
    onmousedown={() => (hovering = false)}
  >
    {#if children}
      {@render children()}
    {:else}
      {@html infoIcon}
    {/if}
  </div>

  {#if trigger && open}
    <div
      transition:fade={{ duration: 200, easing: cubicOut }}
      class="tip"
      role="tooltip"
      use:floating={{ trigger, placement }}
    >
      {tip}
    </div>
  {/if}
</div>
